import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/Home.vue';  // 确保路径正确
import WeChatAuthorize from '../components/WeChatAuthorize.vue';  // 确保路径正确
import DetailInfo from '../components/DetailInfo.vue'; // 导入 Detail 组件
import PaymentSuccess from '../components/PaymentSuccess.vue'; // 导入 PaymentSuccess 组件
import PaymentFailure from '../components/PaymentFailure.vue'; // 导入 PaymentFailure 组件
import Login from '../components/Login.vue';  // 导入 Login.vue 组件

const routes = [
  { path: '/', component: HomePage, name: 'HomePage' },
  { path: '/callback', component: WeChatAuthorize, name: 'WeChatAuthorize' ,meta: { requiresAuth: true }},
  { path: '/Detailinfo', component: DetailInfo, name: 'MfDetailInfo',meta: { requiresAuth: true } },
  { path: '/payment/success', component: PaymentSuccess, name: 'PaymentSuccess',meta: { requiresAuth: true } }, // 添加 PaymentSuccess 路由
  { path: '/payment/failure', component: PaymentFailure, name: 'PaymentFailure',meta: { requiresAuth: true } },  // 添加 PaymentFailure 路由
  { path: '/login', component: Login, name: 'UserLogin' },  // 添加登录页面路由
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 导航守卫
router.beforeEach((to, from, next) => {
  // 检查cooperateCode参数，如果URL中有这个参数就更新缓存
  const cooperateCode = to.query.cooperateCode;
  if (cooperateCode) {
    localStorage.setItem('cooperateCode', cooperateCode);
  }

  const token = localStorage.getItem('token'); 
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !token) {
    next({ name: 'UserLogin' });
  } else {
    next();
  }
});

export default router;