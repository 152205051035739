<template>
    <div class="payment-failure">
      <h1>支付失败</h1>
      <p>很抱歉，您的支付未能成功处理。</p>
      <p>错误信息：{{ errorMessage }}</p>
      <button @click="retryPayment">重试支付</button>
      <button @click="goToHome">返回首页</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        errorMessage: this.$route.query.error || '未知错误',
      };
    },
    methods: {
      retryPayment() {
        // 你可以将用户重定向到支付页面，或者提供其他重试支付的逻辑
        this.$router.push('/payment');
      },
      goToHome() {
        this.$router.push('/');
      },
    },
  };
  </script>
  
  <style scoped>
  .payment-failure {
    text-align: center;
    margin-top: 20px;
  }
  button {
    margin: 10px;
  }
  </style>