<template>
  <div>
    <h1>Authorizing...</h1>
  </div>
</template>

<script>

export default {
  data(){
    return {
      SignInfo:{},
      selectedItem:''
    }
  },
  name: 'WeChatAuthorize',
  created() {
    const code = this.$route.query.code;  // 获取查询参数中的授权码
    const error = this.$route.query.error;
    this.selectedItem = this.$route.query.selectedItem;
    if (error) {
      // 处理授权错误
      this.error = 'Authorization failed: ' + error;
    } else if (code) {
      // 使用授权码交换访问令牌
      this.handleAuthorizationCallback(code);
    } else {
      this.error = 'No authorization code found';
    }
  },
  mounted() {
  },
  methods: {
    async handleAuthorizationCallback(code) {
      try {
        const cooperateCode = localStorage.getItem('cooperateCode') || '';
        await this.$http.post('/WechatJSPAPI/CreateOrder?code='+code+'&selectedItem='+this.selectedItem+'&cooperateCode='+cooperateCode)
        .then(response => {
          if(response.data.Data){
            this.SignInfo = response.data.Data;
            //创建订单成功
            this.invokeWeChatPay(response.data.Data.PrepayId);
            this.startPolling(response.data.Data.OutTradeNo);
          }else{
            alert('创建订单失败');
          }
        });
      } catch (error) {
        alert('Authorization failed:'+ error);
      }
    },
    async invokeWeChatPay(prepayId){
      //Step 2:调用后端获取微信支付参数
      const response = await this.$http.post('/WechatJSPAPI/GetJsApiParameters?prepayId='+prepayId,{
        headers: { 'Authorization': 'Bearer '+localStorage.getItem('token')} 
      });
      if(response.data.Data){
        const data =response.data.Data;
        window.WeixinJSBridge.invoke('getBrandWCPayRequest', 
          {
            "appId": data.appId, // 公众号ID，由商户传入
            "timeStamp": data.timeStamp,      // 时间戳，自1970年以来的秒数
            "nonceStr": data.nonceStr, // 随机串
            "package": data.package,
            "signType": data.signType,     // 微信签名方式
            "paySign": data.paySign, // 微信签名
          }, 
          function(res) {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              // 支付成功后的处理逻辑
              alert("支付成功");
            } else {
              // 支付失败或用户取消支付后的处理逻辑
              alert("取消支付");
            }
          },
        );
      }else{
        alert('微信支付失败');
      }
    },
    startPolling(OutTradeNo) {
      this.pollingInterval = setInterval(() => {
        this.$http.post('/AlipayH5/GetOrders', {OutTradeNo:OutTradeNo}).then(response => {
        if (response.data) {
            if(response.data.Data.State==1){
              clearInterval(this.pollingInterval);
              this.$router.push({ name: 'HomePage',  query: { out_trade_no: OutTradeNo } });
            }
          }
        })
      }, 3000); // 每3秒查询一次
    }
  }
}
</script>