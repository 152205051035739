<template>
  <div class="box_4 flex-col">
    <span class="pay_1">{{ this.paystate }}</span>
    <div class="image-text_4 flex-row justify-between items-center">
      <img
        class="responsive-img"
        referrerpolicy="no-referrer"
        src="../img/pay.png"
        alt="Payment Success Image"
      />
      <div class="text-content">
        <h1>Payment Successful</h1>
        <p>{{ textgroup }}</p>
      </div>
    </div>
    <span class="pay_2">购买成功后请至魔法秘境app查看</span>
    <div class="text-wrapper_1 flex-col">
      <button class="login-button" @click="payOk()">确定</button>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      textgroup: "",
      paystate: ""
    };
  },
  mounted() {
    this.textgroup = localStorage.getItem("textgroup");
    const outTradeNo = localStorage.getItem("outTradeNo");
    this.getorders(outTradeNo);
  },
  methods: {
    payOk() {
      this.$emit("login-success");
    },
    getorders(OutTradeNo) {
      this.$http.post("/AlipayH5/GetOrders", { OutTradeNo: OutTradeNo }).then(response => {
        if (response.data) {
          if (response.data.Data.State == 1) {
            this.paystate = "购买成功！";
          } else {
            this.paystate = "购买失败！";
          }
        } else {
          this.paystate = "购买失败！";
        }
      });
    }
  }
};
</script>

<style scoped>
.box_4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0 auto;
  max-width: 90%;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.image-text_4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  gap: 10px;
}

.responsive-img {
  width: 50px;
  height: 50px;
}

.text-content h1 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 10px 0;
  color: #333;
}

.text-content p {
  font-size: 1rem;
  color: #555;
  margin: 0;
}

.pay_1 {
  font-size: 1.8rem;
  font-weight: 700;
  color: rgba(255, 91, 91, 1);
  margin-bottom: 10px;
}

.pay_2 {
  font-size: 1rem;
  font-weight: 500;
  color: rgba(135, 110, 193, 1);
  margin-top: 10px;
}

.text-wrapper_1 {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-button {
  font-size: 1rem;
  color: #fff;
  background-color: #FF5F5F;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #e54e4e;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 400px;
  text-align: center;
  z-index: 1000;
}

.modal h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.modal p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.modal button {
  font-size: 1rem;
  color: #fff;
  background-color: #FF5F5F;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal button:hover {
  background-color: #e54e4e;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
</style>
