<template>
    <div class="payment-success">
      <h1>支付成功</h1>
      <p>您的支付已成功处理。</p>
      <p>订单号：{{ orderId }}</p>
      <button @click="goToOrderDetails">查看订单详情</button>
      <button @click="goToHome">返回首页</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        orderId: this.$route.query.orderId || '未知',
      };
    },
    methods: {
      goToOrderDetails() {
        this.$router.push({ path: `/order-details/${this.orderId}` });
      },
      goToHome() {
        this.$router.push('/');
      },
    },
  };
  </script>
  
  <style scoped>
  .payment-success {
    text-align: center;
    margin-top: 20px;
  }
  button {
    margin: 10px;
  }
  </style>