// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import axiosInstance from './services/axios';  // 从 axios.js 导入 axios 实例

const app = createApp(App);

// 将 axios 实例挂载到 Vue 实例的全局属性
app.config.globalProperties.$http = axiosInstance;

app.use(router);  
app.mount('#app');