
// src/services/axios.js
import axios from 'axios';

// 创建 axios 实例
const axiosInstance = axios.create({
  baseURL: 'https://adminapi.gogo365.com/', 
  timeout: 10000, 
});

// 配置请求拦截器
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      console.log('已添加 Authorization 头：', config.headers['Authorization']);
    }
    return config;
  },
  error => {
    console.error('请求拦截器出错：', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;